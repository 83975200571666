import axios from 'axios';
import { api_url } from '../../../common/config';

axios.defaults.withCredentials = true;

export default class {
	// login participant endpoint
	static loginParticipantEndpoint = (data) => axios.post(`${api_url}landing/login`, data);

	// register participant endpoint
	static registerParticipantEndpoint = (data) => axios.post(`${api_url}landing/create`, data);

	// register user passport
	static registerParticipantPassportEndpoint = ({ id, token, passport }) =>
		axios.post(`${api_url}landing/update-passport`, {id, passport}, {
			headers: { 'Content-Type': 'application/json; charset=utf-8', Authorization: `Bearer ${token}` },
		});

	// register user passport scan
	static registerParticipantScanPassportEndpoint = ({ id, token, scan }) =>
		axios.post(`${api_url}landing/upload-scan?id=${id}`, scan, {
			headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
		});

	// get participant data
	static getParticipantDataEndpoint = ({ token, id, promotion, }) =>
		axios.post(`${api_url}landing/participant`, {id, promotion}, {
			headers: { 'Content-Type': 'application/json; charset=utf-8', Authorization: `Bearer ${token}` },
		});

	// register participant code and bill
	static registerParticipantBillEndpointOld = ({ token, promotion, participant, codes, qr  }) =>
		axios.post(`${api_url}landing/bill`, {promotion, participant, codes, qr}, {
			headers: { 'Content-Type': 'application/json; charset=utf-8', Authorization: `Bearer ${token}` },
		});

	// register participant code and bill
	static registerParticipantBillEndpoint = ({ token, promotion, participant, code, qr, photoQr, file  }) =>
		//axios.post(`${api_url}landing/bill-new?promotion=${promotion}&participant=${participant}&code=${code}&qr=${qr}&photoQr=${photoQr}`,
		axios.post(`${api_url}landing/bill-new`,
			file, {
			params: {promotion, participant, code, qr, photoQr},
			headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` },
		});

	// register participant question
	static registerParticipantQuestionEndpoint = ({ token, promotion, participant, question }) =>
		axios.post(`${api_url}landing/question`, {promotion, participant, question}, {
			headers: { 'Content-Type': 'application/json; charset=utf-8', Authorization: `Bearer ${token}` },
		});
}
